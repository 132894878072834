var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logContainer" },
    [
      _c("div", { staticClass: "logHeader" }, [_vm._v(" Session Updates ")]),
      _vm._l(_vm.logs, function(log) {
        return _c("div", { key: log.id, staticClass: "log" }, [
          _vm._v(" " + _vm._s(log.message) + " ")
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }