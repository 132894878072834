var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          "background-color": "white",
          "margin-top": "10px",
          width: "300px",
          padding: "10px"
        }
      },
      [
        _c("line-chart", {
          attrs: { "chart-data": _vm.datacollection, width: 200, height: 130 }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          "background-color": "white",
          "margin-top": "10px",
          width: "300px",
          padding: "10px"
        }
      },
      [
        _c("div", { staticStyle: { height: "20px" } }),
        _c("line-chart", {
          attrs: { "chart-data": _vm.datacollection2, width: 200, height: 130 }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          "background-color": "white",
          "margin-top": "10px",
          width: "300px",
          padding: "10px"
        }
      },
      [
        _c("div", { staticStyle: { height: "20px" } }),
        _c("line-chart", {
          attrs: { "chart-data": _vm.datacollection3, width: 200, height: 130 }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }