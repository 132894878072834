var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "navigation" } }, [
    _vm.isLoggedIn
      ? _c("div", [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-self": "space-between",
                border: "0px solid green"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-self": "start",
                    border: "0px solid red",
                    width: "70%"
                  }
                },
                [
                  _vm._m(0),
                  _c("div", { staticClass: "logoTextContainer" }, [
                    _vm._v("Provider Hub")
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: "/home",
                            "active-class": "menuItem",
                            "exact-active-class": "menuItemMatch"
                          }
                        },
                        [_vm._v("Home")]
                      )
                    ],
                    1
                  ),
                  _vm.userType == "JUVA_ADMIN"
                    ? _c("div", [
                        _c("a", { attrs: { href: "/reports" } }, [
                          _vm._v("Reports")
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "end",
                    border: "0px solid purple",
                    width: "30%"
                  }
                },
                [
                  _c("div", [
                    _c("span", { staticClass: "welcomeMessage" }, [
                      _vm._v(_vm._s(_vm.welcomeMessage))
                    ])
                  ]),
                  _vm._m(1)
                ]
              )
            ]
          )
        ])
      : _c("div", [_vm._v(" Not Logged In... ")])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/static/images/Logo.png", height: "50", width: "117" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("a", { attrs: { href: "/accounts/logout" } }, [_vm._v("Logout")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }