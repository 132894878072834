var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "patientTableContainer" } }, [
    _c("span", { staticClass: "panelTitle" }, [_vm._v("Patient Progress")]),
    _c(
      "div",
      { staticClass: "donutBox" },
      [
        _c("vc-donut", { attrs: { sections: _vm.sections } }, [
          _vm._v(_vm._s(_vm.donutCaption))
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }