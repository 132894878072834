import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import TherapySession from '@/views/TherapySession.vue'
import PatientDetails from '@/views/PatientDetails.vue'
import Home from '@/views/Home.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "login",
        component: Login
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/home",
        name: "home",
        component: Home
    },
    {
        path: "/therapy_session",
        name: "therapy_session",
        component: TherapySession
    },
    {
        path: "/patient_details/:patient_juva_id",
        name: "patient_details",
        component: PatientDetails
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router