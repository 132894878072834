<template>
  <div id="patientTableContainer">
    <span class="panelTitle">Patient Progress</span>
    <div class="donutBox">
        <vc-donut :sections="sections">{{ donutCaption }}</vc-donut>
    </div>
  </div>
</template>


<script>
// https://medium.com/ag-grid/using-ag-grid-inside-a-vuejs-application-b6c442d77da8

import Vue from "vue";
import axios from "axios";
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);


export default {
  name: "PatientProgress", // Name of the component
  data() {
    return {
      patientId: null,
      sections: [{ value: 0 }],
      donutCaption:"Learn Juva Program"
    };
  },
  components: {
  },
  created() {
      this.patientId = this.$route.params.patient_juva_id
      this.loadDonut();
  },
  methods: {
    async retrievePatientProgress() {
      var userId = this.$route.params.patient_juva_id;
      return await axios.get("/api/user/" + userId + "/userProgress", {
        headers: {
          Authorization: "Bearer " + this.$session.get("jwt"),
        },
        withCredentials: true,
      });
    },
    loadDonut() {
      this.retrievePatientProgress().then((response) => {
        console.dir(response);
        var learnJuvaStats = response.data.learn_juva_program_statistics;
        this.donutCaption =  learnJuvaStats.completed_lessons + "/" + learnJuvaStats.total_lessons + " completed.  \n Learn Juva Program";
        var percCompleted = (learnJuvaStats.completed_lessons / learnJuvaStats.total_lessons * 100)
        this.sections = [{"value":percCompleted, color:"green"}]
      });
    },
  }
};

// Note: yarn add sass-loader@^10.1.1 is required
</script>

<style scoped>

.patientData {
  margin:5px;
}
.donutBox {
  width:100px;
  margin:0px auto;
  margin-top:30px;
  color:black;
}
</style>

