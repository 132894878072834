var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "white", "margin-top": "10px" } },
    [
      _c("line-chart", {
        attrs: {
          "chart-data": _vm.datacollection,
          width: "400px",
          height: "260px"
        }
      }),
      _c("div", { staticStyle: { height: "20px" } }),
      _c("line-chart", {
        attrs: {
          "chart-data": _vm.datacollection2,
          width: "400px",
          height: "260px"
        }
      }),
      _c("div", { staticStyle: { height: "20px" } }),
      _c("line-chart", {
        attrs: {
          "chart-data": _vm.datacollection3,
          width: "400px",
          height: "260px"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }