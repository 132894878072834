<template>
  <div>
    <div>
      <Navigation />
    </div>
    <div style="margin: 0px auto; color: white;display:flex;">
      <div>
        <div class="column_1">
          <div class="patientInfo panel">
            <PatientInfo :patientId="patientId" />
          </div>
          <div class="patientProgress panel">
            <PatientProgress :patientId="patientId" />
          </div>
        </div>
      </div>
      <div>
        <div class="patientTherapySessions panel">
          <PatientTherapySessions />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../components/Navigation";
import PatientTherapySessions from "../components/PatientTherapySessions";
import PatientInfo from "../components/PatientInfo";
import PatientProgress from "../components/PatientProgress";

export default {
  name: "App",
  data() {
    return {
      patientId: null,
    };
  },
  created() {
    this.initializePage();
  },
  components: {
    PatientTherapySessions,
    PatientInfo,
    PatientProgress,
    Navigation,
  },
  methods: {
    initializePage() {
      this.patientId = this.$route.params.patient_juva_id;
      console.log("initializePage:" + this.patientId);
    },
  },
};
</script>

<style>
.patientInfo {
  width: 300px;
  height: 100px;
  padding: 10px;
}

.patientProgress {
  width: 300px;
  height: 400px;
  padding: 10px;
}

.patientTherapySessions {
  width: 820px;
  padding: 10px;
}
</style>
