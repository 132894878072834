<template>
  <div id="login" style="margin-top:200px;">
    <div style="clear:both"></div>
    <div class="loginPanelContainer">
      <div class="loginPanelHeader">
        <div>
          <img
          src="/static/images/Logo.png"
          height="50"
          width="117"
        />
        </div>
        <div class="logoTextContainer">
          Provider Hub
        </div>
      </div>

      <form
        class="loginPanel"
        @submit.prevent="submitUsername(username, password)"
      >
        <div class="formRow">
          <span class="form-label">Username:</span>
          <input type="text" class="formInput" v-model="username" />
        </div>
        <div class="formRow">
          <span class="form-label">Password:</span>
          <input type="password" class="formInput" v-model="password" />
        </div>
        <button type="submit" class="btn btn-primary mb-2 Botton">
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import axios from "axios";
import { EventBus } from '../Event'

// import components

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      authenticated: this.$session.get("authenticated"),
    };
  },
  created() {
      console.log("Login.vue created...")
  },  
  components: {
  },
  methods: {
    // Generate access token
    async testConnection(_username, _password) {
      return await axios.post(
        "/test/test_connection/",
        { username: _username, password: _password },
        { withCredentials: true }
      );
    },
    async authenticateUser(_username, _password) {
      return await axios.post(
        // "/auth/authenticationService/",
        "/api/authenticationService/",
        { username: _username, password: _password },
        { withCredentials: true }
      );
    },
    submitUsername(username, password) {
      if (!username) {
        return alert("please provide a username");
      }
      // this.testConnection().then((data) => {
      //       console.dir(data)
      // }),
      this.authenticateUser(username, password).then((response) => {
        console.dir(response.data)
        if (response.data.isAuthenticated == true) {
          this.$session.start();
          this.$session.set('jwt', response.data.jwtToken)
          this.$session.set('juvaUserId', response.data.userId)
          this.$session.set('userType', response.data.userType)
          this.$session.set('firstName', response.data.firstName)
          this.$session.set('lastName', response.data.lastName)
          this.$session.set('username', username)
          // Vue.http.headers.common['Authorization'] = 'Bearer ' + response.data.jwtToken
          axios.defaults.headers.common['Authorization'] = 
                    'Bearer ' + response.data.jwtToken;
          this.$session.set("authenticated", true);
          this.$parent.authenticated = true;
          this.$router.push("/home");
          console.log("Token:" + response.data.jwtToken);
          EventBus.$emit('login_complete');
        } else {
          return alert("Sorry -- invalid username or password");
        }
      });
    }
  },
};
</script>

<style>
body {
  background: #2c3e50;
  font-family: "Lato", serif;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 10px;
  background: #2c3e50;
}
.box {
  border: 1px solid gray;
}

.username {
  margin: 12px auto 7px auto;
  color: wheat;
}

.Botton {
  color: #fff;
  background-color: #4d555f;
  border-color: #303840;
  padding: 8px;
  font-weight: bolder;
}

.loginPanel {
  margin: 0px auto;
  color: ghostwhite;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.loginPanelContainer {
  margin-top: 100px;
  margin: 0px auto;
  border: 1px solid ghostwhite;
  width: 800px;
}

.loginPanelHeader {
  color:#2c3e50;
  background-color: white;

  padding: 5px;
  display:flex;
  align-content: center;
}

.logoTextContainer {
  padding:5px;
  margin-top:2px;
  font-size: 28px;
  font-weight: 300;  
  text-transform: uppercase;
  font-family: 'Times New Roman', Times, serif;
}

.form-label {
  width: 150px;
  text-align: right;
}

.formInput {
  width: 150px;
}

.formRow {
  padding: 10px;
  width: 100%;
  text-align: center;
}
</style>
