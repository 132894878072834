var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "patientTableContainer" } },
    [
      _c("span", { staticClass: "panelTitle" }, [
        _vm._v(_vm._s(this.panelTitle))
      ]),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-alpine grid",
        attrs: {
          gridOptions: _vm.gridOptions,
          rowData: _vm.rowData,
          rowDataChanged: _vm.onRowDataChanged
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }