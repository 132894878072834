var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "1150px", margin: "0px auto", "margin-top": "0px" },
      attrs: { id: "app" }
    },
    [
      _c("div", { staticStyle: { margin: "0px auto" } }, [
        _c("div", { staticClass: "row container-fluid chat_container" }, [
          _c("div", [_c("Rooms"), _c("Logs")], 1),
          _c("div", [_c("Video", { attrs: { username: _vm.username } })], 1),
          _c("div", [_c("Charts")], 1)
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }