<template>
<div>
  <div style="background-color:white;margin-top:10px;width:300px;padding:10px;">
    <!-- <button @click="addFakeData()">Add Data</button> -->
    <line-chart :chart-data="datacollection" :width=200 :height=130></line-chart>
  </div>
  <div style="background-color:white;margin-top:10px;width:300px;padding:10px;">
    <div style="height:20px;"/>
    <line-chart :chart-data="datacollection2" :width=200 :height=130></line-chart>
  </div>
  <div style="background-color:white;margin-top:10px;width:300px;padding:10px;">
    <div style="height:20px;"/>
    <line-chart :chart-data="datacollection3" :width=200 :height=130></line-chart>
  </div>
</div>  
</template>

<script>
  import { EventBus } from "../Event";
  import LineChart from './LineChart.js'

  export default {
    components: {
      LineChart
    },
    data () {
      return {
        timepoints:[],
        hrCollection:[],
        rrCollection:[],
        rsCollection:[],
        datacollection: null,
        datacollection2: null,
        datacollection3: null,
        counter:0
      }
    },
    mounted () {
    },
    created () {
      this.fillData()
      // this.initializeData(this.hrCollection, this.rrCollection, this.rsCollection)
      EventBus.$on('initializeCharts', (heartRateDataArray, respiratoryRateDataArray, relaxationScoreDataArray ) => {
          this.initializeData(heartRateDataArray, respiratoryRateDataArray, relaxationScoreDataArray )
      })      
    },    
    methods: {
        data() {
          return {
              options: { //Chart.js options
                  scales: {
                      yAxes: [{
                          ticks: {
                              // beginAtZero: true
                              min:100
                          },
                          gridLines: {
                              display: true
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                              display: true
                          }
                      }]
                  },
                  legend: {
                      display: true
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  bezierCurve: false,
                  height: 10
              }
        }
        },

      fillData () {
          console.log("fillData Called!")

          this.datacollection = {
            labels:this.timepoints,
            datasets: [
              {
                label: "Heart Rate",
                borderColor: '#FB8F89',
                backgroundColor: 'rgb(251, 143, 137, 0.5)',
                data: this.hrCollection,
                lineTension:0,
                suggestedMin: 40,                
                suggestedMax: 140                
              },              
            ],
          },
          this.datacollection2 = {
            labels:this.timepoints,
            datasets: [
              {
                label: "Respiratory Rate",
                backgroundColor: 'blue',
                data: this.rrCollection,
                lineTension:0,
                suggestedMin: 4,                
                suggestedMax: 20
              }
            ]
          },
          this.datacollection3 = {
            labels:this.timepoints,
            datasets: [
              {
                label: "Relaxation Score",
                backgroundColor: 'green',
                data: this.rsCollection,
                lineTension:0,
                suggestedMin: -4,                
                suggestedMax: 4
              }
            ]
          }          
          // console.dir(this.datacollection.datasets[0].data);
      },
      initializeData(heartRateData, respiratoryRateData, relaxationScoreData) {
        // console.log("Initialize data called...")
        // console.dir(heartRateData)
        this.hrCollection = heartRateData;
        this.rrCollection = respiratoryRateData;
        this.rsCollection = relaxationScoreData;
        this.timepoints = Array.from({length: this.hrCollection.length}, (x, i) => i);

        // console.dir(this.timepoints)

        this.fillData();
      }
    }
  }
</script>

<style>
  .small {
    margin-top: 10px;
  }
</style>


