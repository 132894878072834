<template>
  <router-view/>
</template>

<script>

export default {
  name: "Main",
  props: {},
  components: {
  },
  async beforeMount() {

  },
  data: () => ({
  }),
  methods: {
  },
};
</script>

<style>

</style>
