var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "remoteParentContainer" }, [
    _c("div", { staticClass: "roomTitle" }, [
      _vm.loading
        ? _c("span", [_vm._v(" Loading... " + _vm._s(_vm.roomName))])
        : !_vm.loading && _vm.roomName
        ? _c("span", [_vm._v(" Session Started")])
        : _c("span", [_vm._v('Click "Start Session" To Begin')])
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row remote_video_container" }, [
      _c("div", { attrs: { id: "remoteTrack" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }