var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { display: "flex", width: "100%", border: "0px solid white" }
    },
    [
      _c("div", { staticStyle: { width: "100%" } }, [
        _vm.administeredProviders.length > 0
          ? _c("div", { attrs: { id: "" } }, [
              _vm.userType == "PROVIDER" || _vm.userType == "JUVA_ADMIN"
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "20px",
                          width: "100%",
                          "background-color": "white"
                        }
                      },
                      [
                        _vm._v(" Selected Provider: "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedProvider,
                                expression: "selectedProvider"
                              }
                            ],
                            attrs: {
                              name: "provider",
                              placeholder: "Please select a provider"
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedProvider = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.newProviderSelected($event)
                                }
                              ]
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "",
                                  readonly: "true",
                                  hidden: "true",
                                  selected: ""
                                }
                              },
                              [_vm._v(" Please select a provider... ")]
                            ),
                            _vm._l(_vm.administeredProviders, function(option) {
                              return _c(
                                "option",
                                { key: option.id, domProps: { value: option } },
                                [_vm._v(" " + _vm._s(option.name) + " ")]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "patients panel" },
                [_c("PatientTable")],
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }