var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_c("Navigation")], 1),
    _vm.userType == "PROVIDER" || _vm.userType == "JUVA_ADMIN"
      ? _c("div", [_c("ProviderPage")], 1)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }