import Vue from 'vue'
import App from './App.vue'
import VueSession from "vue-session";
import VueResource from 'vue-resource'
import router from './router'
import axios from 'axios';
axios.defaults.withCredentials = true;

Vue.use(VueSession);
Vue.use(VueResource);

Vue.config.productionTip = false



new Vue({
    render: h => h(App),
    router,
}).$mount('#app')