var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_c("Navigation")], 1),
    _c(
      "div",
      { staticStyle: { margin: "0px auto", color: "white", display: "flex" } },
      [
        _c("div", [
          _c("div", { staticClass: "column_1" }, [
            _c(
              "div",
              { staticClass: "patientInfo panel" },
              [_c("PatientInfo", { attrs: { patientId: _vm.patientId } })],
              1
            ),
            _c(
              "div",
              { staticClass: "patientProgress panel" },
              [_c("PatientProgress", { attrs: { patientId: _vm.patientId } })],
              1
            )
          ])
        ]),
        _c("div", [
          _c(
            "div",
            { staticClass: "patientTherapySessions panel" },
            [_c("PatientTherapySessions")],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }