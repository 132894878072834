<template>
  <div style="background-color:white;margin-top:10px;">
    <!-- <button @click="addFakeData()">Add Data</button> -->
    <line-chart :chart-data="datacollection" width='400px' height='260px'></line-chart>
    <div style="height:20px;"/>
    <line-chart :chart-data="datacollection2" width='400px' height='260px'></line-chart>
    <div style="height:20px;"/>
    <line-chart :chart-data="datacollection3" width='400px' height='260px'></line-chart>
  </div>
</template>

<script>
  import { EventBus } from "../Event";
  import LineChart from './LineChart.js'

  export default {
    components: {
      LineChart
    },
    data () {
      return {
        datacollection: null,
        name:"HeartRate",
        hrCollection:[],
        timepoints:[],
        counter:0
      }
    },
    mounted () {
      this.fillData()
    },
    created () {
      EventBus.$on('new_biomarkers', (data) => {
          this.counter++;
          if (this.counter % 15 == 0) {
            data = JSON.parse(data);
            var heartRate = data["HR"]
            var respiratoryRate = data["RR"]
            var relaxationScore = data["RS"]

            if (heartRate > 0) {
              this.addData(heartRate, respiratoryRate, relaxationScore);
            }
          }
      })      
      this.hrCollection = []
      this.rrCollection = []
      this.rsCollection = []
    },    
    methods: {
        data() {
          return {
              options: { //Chart.js options
                  scales: {
                      yAxes: [{
                          ticks: {
                              // beginAtZero: true
                              min:100
                          },
                          gridLines: {
                              display: true
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                              display: true
                          }
                      }]
                  },
                  legend: {
                      display: true
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  bezierCurve: false,
                  height: 10
              }
        }
        },

      fillData () {
          console.log("fillData Called!")

          this.datacollection = {
            labels:this.timepoints,
            datasets: [
              {
                label: this.name,
                borderColor: '#FB8F89',
                backgroundColor: 'rgb(251, 143, 137, 0.5)',
                data: this.hrCollection,
                lineTension:0,
                suggestedMin: 40,                
                suggestedMax: 140                
              },              
            ],
          },
          this.datacollection2 = {
            labels:this.timepoints,
            datasets: [
              {
                label: "Respiratory Rate",
                backgroundColor: 'blue',
                data: this.rrCollection,
                lineTension:0,
                suggestedMin: 4,                
                suggestedMax: 20
              }
            ]
          },
          this.datacollection3 = {
            labels:this.timepoints,
            datasets: [
              {
                label: "Relaxation Score",
                backgroundColor: 'green',
                data: this.rsCollection,
                lineTension:0,
                suggestedMin: -4,                
                suggestedMax: 4
              }
            ]
          }          
          // console.dir(this.datacollection.datasets[0].data);
      },
      addFakeData() {
        this.addData(this.getRandomInt(),this.getRandomInt(),this.getRandomInt());
      },
      addData(heartRate, respiratoryRate, relaxationScore) {
        this.hrCollection.push(heartRate);
        this.rrCollection.push(respiratoryRate);
        this.rsCollection.push(relaxationScore);
        this.timepoints.push(this.timepoints.length + 1);
        this.fillData();
      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }
    }
  }
</script>

<style>
  .small {
    margin-top: 10px;
  }
</style>


