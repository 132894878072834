<template>
  <div id="patientTableContainer">
    <span class="panelTitle">{{ this.panelTitle }}</span>
    <ag-grid-vue
      class="ag-theme-alpine grid"
      :gridOptions="gridOptions"
      :rowData="rowData"
      :rowDataChanged="onRowDataChanged"
    >
    </ag-grid-vue>
  </div>
</template>


<script>
// https://medium.com/ag-grid/using-ag-grid-inside-a-vuejs-application-b6c442d77da8

import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import { EventBus } from '../Event'

export default {
  name: "PatientTable", // Name of the component
  data() {
    return {
      provider:this.$session.get("selectedProvider"), 
      gridOptions: null,
      rowData: null,
      panelTitle: "Welcome back",
    };
  },
  components: {
    AgGridVue,
  },
  created() {
    EventBus.$on('provider_registration_complete', (data) => {
      this.provider = this.$session.get("selectedProvider");
      this.loadRowData();
    });

    this.gridOptions = {
        defaultColDef : { 
            sortable: true, 
            filter: true
            },
      },

    this.gridOptions.columnDefs = this.createColDefs();

    if (this.$session.get("selectedProvider")) {
      this.provider = this.$session.get("selectedProvider");
      this.loadRowData();
    }
  },
  computed: {
  },
  methods: {

    async retrievePatientList() {
      var providerId = this.provider.id;
      return await axios.get("/api/provider/" + providerId + "/members", {
        headers: {
          Authorization: "Bearer " + this.$session.get("jwt"),
        },
        withCredentials: true,
      });
    },
    loadRowData() {
      this.retrievePatientList().then((response) => {
        // console.log("loadRowData... ");
        // console.dir(response);
        this.rowData = response.data;
        this.panelTitle = this.provider.name + " (" + this.rowData.length + " Patients)"
      });
    },
    createColDefs() {
      return [
        { headerName: "Id", 
          field: "id",
          width:80,
          sort:"desc"
        },
        {
          headerName: "Details",
          field: "id",
          width: 200,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
              // console.dir("cell renderer")
              // console.dir(params)
              const route = {
                name: "patient_details",
                params: { patient_juva_id: params.value }
              };
              const link = document.createElement("a");
              link.href = this.$router.resolve(route).href;
              link.innerText = "Details";
              link.addEventListener("click", e => {
                e.preventDefault();
                this.$router.push(route);
              });
              return link;
          }           
        },
      ];
    },
    onRowDataChanged() {
      Vue.nextTick(() => {
        this.gridOptions.api.sizeColumnsToFit();
      });
    },
  },
};

// Note: yarn add sass-loader@^10.1.1 is required
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
<style scoped>

.panelTitle {
  padding:10px;
  margin:10px;
  font-size:18px;
}
.grid {
  height: 800px;;
}
</style>

