<template>
   <div class="rooms">
       <div class="room" v-for="room in rooms" v-bind:key="room.id"  @click="showRoom(room, $event)">
            <div v-if="room.is_started">
                      <!-- {{room.name}} --> End Session
            </div>
            <div v-else>
                      <!-- {{room.name}} --> Start Session
            </div>
       </div>
      <div id="localTrack"></div>
   <!--  <AddRoom /> removed AddRoom component -->
   </div>
</template>

<script>

import Twilio, {
  connect,
  createLocalTracks,
  createLocalVideoTrack,
} from "twilio-video";

import { EventBus } from '../Event'
import AddRoom from '../components/AddRoom'

export default {
 name: "Rooms", // Name of the component
 data() {
   return {
       rooms: [
           {id: 1, name: 'JuvaPOC', is_started: false}
       ],
       roomCount: 1, // used to keep track of the number of rooms present
       loading: false, // indicate when tracks in a room is being loaded
   }
 },
 components: {
    //  AddRoom // imported AddRoom component
 },
 created() {
    EventBus.$on('new_room', (data) => {
    this.roomCount++;
    this.rooms.push({id: this.roomCount, name: data});
   });

    if (!this.localTrack) {
      createLocalVideoTrack().then((track) => {
        let localMediaContainer = document.getElementById("localTrack");
        localMediaContainer.appendChild(track.attach());
        this.localTrack = true;
      });
    }

 },
 methods: {
   showRoom(room, event) {
      if (room.is_started) {
       room.is_started = false;
       EventBus.$emit('exit_room', room.name);
      } else {
       room.is_started = true;
       EventBus.$emit('show_room', room.name);
      }
   }
 }

}
</script>

<style scoped> /* scoped attribute is used here so the styling applies to this component alone */
   .rooms > .room {
       border: 1px solid rgb(124, 129, 124);
       padding: 13px;
       margin:10px;
       background-color: ghostwhite;
       color:#2c3e50;
       width:300px;
       border-radius: 10px;
       text-align:center;
   }

   .rooms {
     border: 1px solid rgb(64, 68, 64);
     
     cursor: pointer;
   }
</style>
<style>

    #localTrack video {
     /* 
     max-width:300px !important;  */
      border: 1px solid rgb(124, 129, 124);
      margin: 10px;
      width:300px !important;      
      max-width: 300px !important; 
      background-repeat: no-repeat;
    }

</style>
