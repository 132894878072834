<template>
  <div>
    <div id="therapySessionTableContainer">
      <div class="panelTitle">Patient Therapy Sessions</div>
        <div class="patientTherapyContainer" >
        <ag-grid-vue
          class="ag-theme-alpine grid"
          :gridOptions="gridOptions"
          :rowData="rowData"
          :rowDataChanged="onRowDataChanged"
          :rowSelection="rowSelection"
          @selection-changed="onSelectionChanged"
        >
        </ag-grid-vue>
        <!-- TODO: Fix this --  we needed to initilize the MiniCharts, but don't want to actually show the data due to null pointer execption-->
          <div id="therapySessionDetails" v-show="hasSelectedRow">

        <table><tr>
          <td style="vertical-align:top">
            <div v-if="hasSelectedRow">
              <div style="width:435px;padding:20px;color:white;">
                <div class="sectionTitle">
                  Session Info
                </div>
                <div class="sectionData">
                  <span class="label">Id:</span> {{ selectedRowData.id }}
                </div>
                <div class="sectionData">
                 <span class="label">Type:</span> {{ selectedRowData.sessionType }}
                </div>
                <div class="sectionData">
                <span class="label">Session Date:</span> {{ formatLongDateFromMillis(selectedRowData.sessionStartTimeSeconds) }}
                </div>
                <div class="sectionData">
                <span class="label">Session Length (HH:MM:SS):</span> {{ new Date(selectedRowData.sessionLengthSeconds * 1000).toISOString().substr(11, 8) }}
                </div>
                <div class="sectionTitle">
                  Biometric Changes
                </div>
                <div class="sectionData biometricChanges" >
                  <table width="100%" style="color:#EEE">
                    <tr>
                      <th></th><th>Start</th><th>End</th><th>Change</th>
                    </tr>
                    <tr>
                      <td class="label">Heart Rate</td><td>{{ selectedRowData.heartRateStartValue.toFixed(2) }}</td><td>{{ selectedRowData.heartRateEndValue.toFixed(2) }}</td><td>{{ selectedRowData.heartRateChangeValue.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td  class="label">Respiratory Rate</td><td>{{ selectedRowData.respiratoryRateStartValue.toFixed(2) }}</td><td>{{ selectedRowData.respiratoryRateEndValue.toFixed(2) }}</td><td>{{ selectedRowData.respiratoryRateChangeValue.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td  class="label">Relaxation Score:</td><td>{{ selectedRowData.relaxationScoreStartValue.toFixed(2) }}</td><td>{{ selectedRowData.relaxationScoreEndValue.toFixed(2) }}</td><td>{{ selectedRowData.relaxationScoreChangeValue.toFixed(2) }}</td>
                    </tr>
                    </table>
                </div>
                <div class="sectionTitle">
                  Self Reported Data
                </div>
                <div class="sectionData biometricChanges">
                  <table width="100%" style="color:#EEE">
                    <tr>
                      <th></th><th>Before</th><th>After</th>
                    </tr>
                    <tr>
                      <td class="label">Heart Rate</td><td>{{ selectedRowData.preTherapyHeadacheScore.toFixed(2) }}</td><td>{{ selectedRowData.postTherapyHeadacheScore.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td  class="label">Respiratory Rate</td><td>{{ selectedRowData.preTherapyStressScore.toFixed(2) }}</td><td>{{ selectedRowData.postTherapyStressScore.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td  class="label">Relaxation Score:</td><td>{{ selectedRowData.preTherapyTensenessScore.toFixed(2) }}</td><td>{{ selectedRowData.postTherapyTensenessScore.toFixed(2) }}</td>
                    </tr>
                    </table>
                </div>                
              </div>
            </div>
          </td>
          <td style="vertical-align:top">              
            <div style="width:300px;padding:20px;">
              <MiniCharts :hrCollection="heartRateDataArray"/>
            </div>
          </td>
          </tr>
          </table>

          </div>
        </div>
      </div>
  </div>
</template>


<script>
// https://medium.com/ag-grid/using-ag-grid-inside-a-vuejs-application-b6c442d77da8

import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import MiniCharts from "../components/MiniCharts";
import { EventBus } from "../Event";

export default {
  name: "Therapy", // Name of the component
  data() {
    return {
      gridOptions: null,
      rowData: null,
      rowSelection: 'single',
      selectedRowData: [],
      hasSelectedRow: false,
      heartRateDataArray:[]
    };
  },
  components: {
    AgGridVue,
    MiniCharts
  },
  created() {
    this.gridOptions = {
        defaultColDef : { 
            sortable: true, 
            filter: true
            },
      },

    this.gridOptions.columnDefs = this.createColDefs();
    // console.dir(this.gridOptions)
    this.loadRowData();

  },
  mounted () {
    this.gridOptions.api.forEachNode(node => print(node))
  },  
  methods: {
    async retrievePatientTherapySessions() {
      var userId = this.$route.params.patient_juva_id;
      return await axios.get("/api/user/" + userId + "/therapySessions", {
        headers: {
          Authorization: "Bearer " + this.$session.get("jwt"),
        },
        withCredentials: true,
      });
    },
    loadRowData() {
      // this.retrievePatientTherapySessions().then((response) => {
      //   this.rowData = response.data;
      // });
    },
    createColDefs() {
      return [
        { headerName: "Session Id", 
          field: "id",
          width:150,
          sort:"desc"
        },
        { headerName: "Session Type", 
          field: "sessionType",
          width:300,
        },
        { headerName: "Session Length", 
          field: "sessionLengthSeconds",
          width:150,
          valueGetter: function (params) { 
            var totalSeconds = params.data.sessionLengthSeconds
            var returnValue = new Date(totalSeconds * 1000).toISOString().substr(11, 8)
            return returnValue
          },

        },
        { headerName: "Session Date", 
          field: "sessionStartTimeSeconds",
          width:200,
          valueGetter: function (params) { 
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(params.data.sessionStartTimeSeconds);
            var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
            return d.toLocaleDateString("en-US",options);
          },
        },
      ];
    },
    formatLongDateFromMillis(millis) {
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(millis);
        var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        return d.toLocaleDateString("en-US",options);
    },
    onRowDataChanged() {
      Vue.nextTick(() => {
        this.gridOptions.api.sizeColumnsToFit();
      });
    },
    onSelectionChanged() {
      var selectedRows = this.gridOptions.api.getSelectedRows();
      this.selectedRowData = selectedRows[0];
      console.dir(this.selectedRowData );
      this.hasSelectedRow = true;
      this.heartRateDataArray = this.selectedRowData.heartRateData.split(",")
      this.respiratoryRateDataArray = this.selectedRowData.respiratoryRateData.split(",")
      this.relaxationScoreDataArray = this.selectedRowData.relaxationScoreData.split(",")
      this.updateSessionDetails();
    },
    updateSessionDetails() {
      EventBus.$emit("initializeCharts", this.heartRateDataArray, this.respiratoryRateDataArray, this.relaxationScoreDataArray);
    }
  },
};

// Note: yarn add sass-loader@^10.1.1 is required
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
<style scoped>
.grid {
  height:1000px;
  width:796px;
}
.sectionTitle {
  margin-top:10px;
  font-weight: bold;
  margin-bottom:5px;
}

.sectionData {
  padding:5px;
  color:#EEE  
}

.sectionData .label {
  font-weight:bold;
  color:#EEE
}

.patientTherapyContainer {
  background-color:#2A5B7C;
}

.biometricChanges TD {
  text-align:center;
  width:10%;
}

.biometricChanges .label {
  text-align:right;
  font-weight:bold;
  width:50%;
}


.biometricChanges TH {
  width:15%;
  text-align:center;
}


</style>

