<template>
  <div id="patientTableContainer">
    <span class="panelTitle">Patient Info</span>
    <div class="patientData">
      <span class="label">Id: {{ patientId }}</span>
    </div>
  </div>
</template>


<script>
// https://medium.com/ag-grid/using-ag-grid-inside-a-vuejs-application-b6c442d77da8


export default {
  name: "PatientInfo", // Name of the component
  data() {
    return {
      patientId: null,
    };
  },
  components: {
  },
  created() {
      this.patientId = this.$route.params.patient_juva_id
  },
  methods: {
  },
};

// Note: yarn add sass-loader@^10.1.1 is required
</script>

<style scoped>

.patientData {
  margin:5px;
  font-size:20px;
}
</style>

