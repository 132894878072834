<template>
  <div style="display: flex; width: 100%; border: 0px solid white">
    <div style="width: 100%">
      <div id="" style="" v-if="administeredProviders.length > 0">
        <div v-if="userType == 'PROVIDER' || userType == 'JUVA_ADMIN'">
          <div style="padding: 20px; width: 100%; background-color: white">
            Selected Provider:
            <select
              name="provider"
              v-model="selectedProvider"
              placeholder="Please select a provider"
              @change="newProviderSelected($event)"
            >
              <option value="" readonly="true" hidden="true" selected>
                Please select a provider...
              </option>
              <option
                v-for="option in administeredProviders"
                v-bind:key="option.id"
                v-bind:value="option"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="patients panel">
          <PatientTable />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import PatientTable from "../components/PatientTable";
import { EventBus } from "../Event";

export default {
  name: "ProviderPage", // Name of the component
  data() {
    return {
      isLoggedIn: this.$session.get("authenticated"),
      userType: this.$session.get("userType"),
      providerName: "", // indicate when tracks in a room is being loaded
      administeredProviders: [],
      selectedProvider: null,

    };
  },
  components: {
    PatientTable,
  },
  created() {
    this.retrieveAdministeredProviders().then((response) => {
      console.dir(response);
      this.administeredProviders = response.data;
      // this.$session.set('administeredProviders', administeredProviders)

      if (this.$session.get("selectedProvider")) {
        this.selectedProvider = this.$session.get("selectedProvider");
      } else {
        if (this.administeredProviders.length > 0) {
          // TODO -- suppport dropdown for multple clinics -- for now, just take the first one...
          this.selectedProvider = this.administeredProviders[0];
          // console.dir(selectedProvider)
          this.providerName = this.selectedProvider.name;
          this.$session.set("selectedProvider", this.selectedProvider);
          console.dir(this.selectedProvider);

          // For now, only the primary contact can login -- but this may change in the future, so be ready to get the profile from
          //  a different service
          this.$session.set(
            "session_user",
            this.selectedProvider.primaryContact
          );
        } else {
          this.providerName = "None";
        }
      }

      EventBus.$emit("provider_registration_complete");
      // console.dir(this.$session.getAll());
    });
  },
  methods: {
    // Generate access token
    async retrieveAdministeredProviders() {
      // console.log("Using..***.." + "Bearer " + this.$session.get('jwt'));
      var juvaUserId = this.$session.get("juvaUserId");
      return await axios.get("/api/provider/administeredBy/" + juvaUserId, {
        headers: {
          Authorization: "Bearer " + this.$session.get("jwt"),
        },
        withCredentials: true,
      });
    },
    newProviderSelected(event) {
      // console.dir(event.target.selectedIndex)
      // console.dir(this.selectedProvider)
      this.$session.set("selectedProvider", this.selectedProvider);
      this.$session.set("session_user", this.selectedProvider.primaryContact);
      // console.log(      this.selectedProvider.id);
      // console.log(      this.$session.get("selectedProvider").id);
      EventBus.$emit("provider_registration_complete");
    },
  },
};
</script>

<style scoped>
.panel {
  border: 1px solid rgb(124, 129, 124);
  background-color: white;
}

.provider {
  padding: 10px;
}

.appointments {
  padding: 10px;
}

.dashboard {
  padding: 10px;
}

.patients {
  margin: 0px auto;
  margin-top: 20px;
  width: 95%;
}

.panelTitle {
  font-weight: bold;
  margin-bottom: 5px;
}
</style>

