<template>
  <div id="app" style="width: 1150px; margin: 0px auto; margin-top: 0px">
    <div class="" style="margin: 0px auto">
        <div class="row container-fluid chat_container">
          <div>
            <Rooms />
            <Logs />
          </div>
          <div>
            <Video :username="username" />
          </div>
          <div>
            <Charts />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// import components
import Rooms from "../components/Rooms";
import Video from "../components/Video";
import Logs from "../components/Logs";
import Login from "../components/Login";
import Navigation from "../components/Navigation";
import Charts from "../components/Charts";

export default {
  name: "App",
  data() {
    return {
      authenticated: this.$session.get("authenticated")
    };
  },
  components: {
    Rooms,
    Video,
    Logs,
    Charts,
  },
  methods: {
  }
};
</script>

<style>
body {
  background: #2c3e50;
  font-family: "Lato", serif;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 10px;
  background: #2c3e50;
}
.box {
  border: 1px solid gray;
}

.username {
  margin: 12px auto 7px auto;
  color: wheat;
}

.Botton {
  color: #fff;
  background-color: #4d555f;
  border-color: #303840;
  padding: 8px;
  font-weight: bolder;
}

</style>
