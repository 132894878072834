<template>
    <div class="logContainer">
        <div class="logHeader">
            Session Updates
        </div>
        <div class="log"  v-for="log in logs" v-bind:key="log.id">
            {{log.message}}
        </div>
    </div>
</template>

<script>

import { EventBus } from '../Event'
import axios from 'axios'

export default {
  name: "Logs",
  data() {
    return {
        logs: [],
        logCount: 0
    }
  },
  created() {
    console.log("in Logs Created")
    console.dir(EventBus)
    if (EventBus != null) {
        EventBus.$on('new_log', (message) => {
            this.logs.push( {id: this.logCount, message: message} );
            this.logCount += 1; 
        });
    } else {
        console.log("Sorry, no EventBus Found");
    }
  },
}
</script>

<style scoped>
    .logContainer {
        margin:10px;
        border: 1px solid rgb(124, 129, 124);
        height:508px;
    }
    .log {
        border: 1px solid rgb(124, 129, 124);
        padding: 13px;
        margin: 3px 0px;
        color: ghostwhite;
    }
    .logHeader {
        border-bottom: 1px solid rgb(124, 129, 124);
        padding: 13px;
        margin: 0px 0px;
        background-color: #5e83a8;
        color:ghostwhite;
    }

</style>
