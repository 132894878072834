<template>
  <div>
    <div>
      <Navigation />
    </div>
    <div v-if="userType == 'PROVIDER' || userType == 'JUVA_ADMIN'">
      <ProviderPage />
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Navigation from "../components/Navigation";
import ProviderPage from "../components/ProviderPage";
import { EventBus } from "../Event";

export default {
  name: "Home", // Name of the component
  data() {
    return {
      isLoggedIn: this.$session.get("authenticated"),
      userType: this.$session.get("userType"),
      providerName: "", // indicate when tracks in a room is being loaded
      administeredProviders: [],
      selectedProvider: null,
    };
  },
  components: {
    ProviderPage,
    Navigation,
    //  AddRoom // imported AddRoom component
  },
  created() {
    this.retrieveAdministeredProviders().then((response) => {
      console.dir(response);
      this.administeredProviders = response.data;
      // this.$session.set('administeredProviders', administeredProviders)

      if (this.$session.get("selectedProvider")) {
        this.selectedProvider = this.$session.get("selectedProvider");
      } else {
        if (this.administeredProviders.length > 0) {
          // TODO -- suppport dropdown for multple clinics -- for now, just take the first one...
          this.selectedProvider = this.administeredProviders[0];
          // console.dir(selectedProvider)
          this.providerName = this.selectedProvider.name;
          this.$session.set("selectedProvider", this.selectedProvider);
          console.dir(this.selectedProvider);

          // For now, only the primary contact can login -- but this may change in the future, so be ready to get the profile from
          //  a different service
          this.$session.set(
            "session_user",
            this.selectedProvider.primaryContact
          );
        } else {
          this.providerName = "None";
        }
      }

      EventBus.$emit("provider_registration_complete");
      // console.dir(this.$session.getAll());
    });
  },
  methods: {
    // Generate access token
    async retrieveAdministeredProviders() {
      // console.log("Using..***.." + "Bearer " + this.$session.get('jwt'));
      var juvaUserId = this.$session.get("juvaUserId");
      return await axios.get("/api/provider/administeredBy/" + juvaUserId, {
        headers: {
          Authorization: "Bearer " + this.$session.get("jwt"),
        },
        withCredentials: true,
      });
    },
    newProviderSelected(event) {
      // console.dir(event.target.selectedIndex)
      // console.dir(this.selectedProvider)
      this.$session.set("selectedProvider", this.selectedProvider);
      this.$session.set("session_user", this.selectedProvider.primaryContact);
      // console.log(      this.selectedProvider.id);
      // console.log(      this.$session.get("selectedProvider").id);
      EventBus.$emit("provider_registration_complete");
    },
  },
};
</script>

<style>
</style>

