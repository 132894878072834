var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rooms" },
    [
      _vm._l(_vm.rooms, function(room) {
        return _c(
          "div",
          {
            key: room.id,
            staticClass: "room",
            on: {
              click: function($event) {
                return _vm.showRoom(room, $event)
              }
            }
          },
          [
            room.is_started
              ? _c("div", [_vm._v(" End Session ")])
              : _c("div", [_vm._v(" Start Session ")])
          ]
        )
      }),
      _c("div", { attrs: { id: "localTrack" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }