<template>
  <div id="navigation">
    <div v-if="isLoggedIn">
      <div
        style="display: flex; align-items: center; justify-self: space-between;border: 0px solid green;"
      >
        <div style="display: flex; align-items: center; justify-self: start;border:0px solid red;width:70%;">
          <div>
            <img src="/static/images/Logo.png" height="50" width="117" />
          </div>
          <div class="logoTextContainer">Provider Hub</div>
          <div>
            <router-link
              to="/home"
              active-class="menuItem"
              exact-active-class="menuItemMatch"
              >Home</router-link
            >
          </div>
          <!-- <div>
            <router-link
              to="/therapy_session"
              active-class="menuItem"
              exact-active-class="menuItemMatch"
              >Patient Session</router-link
            >
          </div> -->
          <div v-if="userType == 'JUVA_ADMIN'">
            <a href="/reports">Reports</a>
          </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: end; border: 0px solid purple;width:30%;">
          <div>
            <span class="welcomeMessage">{{ welcomeMessage }}</span>
          </div>
          <div>
            <a href="/accounts/logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Not Logged In...

      <!-- <div
        class="rightSection"
        style="margin-left: 10px; padding: 14px; text-align: left;height:50px;border:0px solid red;"
      >
        <img
          src="https://www.juvahealth.com/wp-content/uploads/2020/05/Juva-Logo-Alt-Small.svg"
          height="50"
          width="117"
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import { EventBus } from "../Event";

export default {
  name: "Navigation",
  data() {
    return {
      isLoggedIn: this.$session.get("authenticated"),
      userType: this.$session.get("userType"),
      welcomeMessage: "",
    };
  },
  created() {
    EventBus.$on("login_complete", (data) => {
      this.loginComplete();
    });

    if (this.$session.get("authenticated") != false) {
      console.log("Is Authenticated");
      this.loginComplete();
    } else {
      // redirect to login
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$parent.authenticated = false;
      this.isLoggedIn = false;
      this.$router.push("/");
    },
    loginComplete() {
      this.isLoggedIn = true;
      this.welcomeMessage = this.getWelcomeMessage();
    },
    getWelcomeMessage() {
      this.$session.start();
      // console.log("getWelcomeMessage")
      // console.dir(this.$session.get("session_user").profile)
      return (
        "Welcome Back " +
        this.$session.get("firstName") +
        " " +
        this.$session.get("lastName") +
        " (" +
        this.$session.get("userType") + ") "
      );
    },
  },
};
</script>

<style>
#navigation {
  color: #2c3e50;
  height: 60px;
  margin-bottom: 0px;
  background-color: white;
  border: 0px solid red;
  width: 100%;
}
#navigation .menuItem {
  margin-left: 20px;
  padding: 10px;
}

#navigation .welcomeMessage {
  margin-left: 20px;
  padding: 10px;
  font-size:14px;
  color: #2c3e50;
}

#navigation .menuItemMatch {
  margin-left: 20px;
  padding: 10px;
  color: #2c3e50;
  font-weight: bold;
}

#navigation a {
  margin-left: 20px;
  padding: 10px;
  color: #2c3e50;
  text-decoration: none;
}
</style>
