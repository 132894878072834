<template>
  <div class="remoteParentContainer">
    <div class="roomTitle">
      <span v-if="loading"> Loading... {{ roomName }}</span>
      <span v-else-if="!loading && roomName"> Session Started</span>
      <span v-else>Click "Start Session" To Begin</span>
    </div>
    <div class="row remote_video_container">
      <div id="remoteTrack"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../Event";
import Twilio, {
  connect,
  createLocalTracks,
  createLocalVideoTrack,
} from "twilio-video";
import axios from "axios";

export default {
  name: "Video",
  data() {
    return {
      loading: false,
      data: {},
      localTrack: false,
      remoteTrack: "",
      activeRoom: "",
      previewTracks: "",
      identity: "",
      roomName: null,
    };
  },
  props: ["username"], // props that will be passed to this component
  created() {
     EventBus.$on('show_room', (room_name) => {
         this.createChat(room_name);
     })
     EventBus.$on('exit_room', (room_name) => {
         this.leaveRoomIfJoined();
     })


   // When a user is about to transition away from this page, 
   // disconnect from the room, if joined.
   window.addEventListener('beforeunload', this.leaveRoomIfJoined);
  },
  methods: {
    // Generate access token
    async getAccessToken(room_name) {
      return await axios.get(
        `https://juvahealthserverstage.herokuapp.com/api/requestTokenForRoomWebApp?userId=${this.username}&roomId=` + room_name
      );
    },

    // Trigger log events
    dispatchLog(message) {
      console.log("inside dispatchLog:" + message);
      EventBus.$emit("new_log", message);
    },

    // Attach the Tracks to the DOM.
    attachTracks(tracks, container) {
      tracks.forEach(function (trackContainer) {

        console.dir("------")
        console.dir(trackContainer)
        console.dir(trackContainer.constructor.name)

        var trackContainerClass = trackContainer.constructor.name
        var track = null;

        if ((trackContainerClass == "RemoteAudioTrackPublication") || 
              (trackContainerClass == "RemoteVideoTrackPublication") || 
              (trackContainerClass == "RemoteDataTrackPublication")) {
                  console.log("Track extracted from parent")
                  console.dir(track)
                  track = trackContainer.track
              } else {
                  track = trackContainer 
              }
        
        if (track != null) {
          if (track.kind === 'data') {
            track.on('message', data => {
                EventBus.$emit("new_biomarkers", data);
            });
          } else if (track.isSubscribed == false) {
            console.log("not a subscribed track.... Skipping -- " + track)
          } else {                   
            container.appendChild(track.attach());
          }        
        } else {
           console.log("Trouble connecting " + trackContainer);
        }

      });
    },

    // Attach the Participant's Tracks to the DOM.
    attachParticipantTracks(participant, container) {
      console.log("attachParticipantTracks called for " + participant.identity)
      let tracks = Array.from(participant.tracks.values());
      this.attachTracks(tracks, container);
    },

    // Detach the Tracks from the DOM.
    detachTracksOrig(tracks) {
      tracks.forEach((track) => {
        track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
      });
    },

    detachTracks(tracks) {
      tracks.forEach((trackContainer) => {

        var trackContainerClass = trackContainer.constructor.name
        var track = null;

        if ((trackContainerClass == "RemoteAudioTrackPublication") || 
              (trackContainerClass == "RemoteVideoTrackPublication") || 
              (trackContainerClass == "RemoteDataTrackPublication")) {
                  console.log("Track extracted from parent")
                  console.dir(track)
                  track = trackContainer.track
              } else {
                  track = trackContainer 
              }
        
        console.dir(track)

        if (track != null) {
          track.detach().forEach((detachedElement) => {
            detachedElement.remove();
          });
        }

        // TODO -- remove the html container for the twilio video view...
        let previewContainer = document.getElementById("remoteTrack");
        previewContainer.innerHTML = ""

      });
    },    

    // Detach the Participant's Tracks from the DOM.
    detachParticipantTracks(participant) {
      let tracks = Array.from(participant.tracks.values());
      this.detachTracks(tracks);
    },

    // Leave Room.
    leaveRoomIfJoined() {
      if (this.activeRoom) {
        this.dispatchLog("Leaving Room: " + this.activeRoom.name);
        this.activeRoom.disconnect();
      }
    },

    // Create chat room
    createChat(room_name) {
      this.loading = true;
      const VueThis = this;

      this.getAccessToken(room_name).then((data) => {
        VueThis.roomName = null;
        const token = data.data.token;
        let connectOptions = {
          name: room_name,
          // logLevel: 'debug',
          audio: true,
          video: { width: 400 },
          automaticSubscription: true,
        };
        console.dir(connectOptions);
        // before a user enters a new room,
        // disconnect the user from they joined already
        this.leaveRoomIfJoined();

        // remove any remote track when joining a new room
      
        document.getElementById("remoteTrack").innerHTML = "";

        Twilio.connect(token, connectOptions).then(function (room) {
          // console.log('Successfully joined a Room: ', room);
          VueThis.dispatchLog("Successfully joined a Room: " + room_name);

          // set active toom
          VueThis.activeRoom = room;
          VueThis.roomName = room_name;
          VueThis.loading = false;

          // Attach the Tracks of all the remote Participants.

          room.participants.forEach(function (participant) {  
            VueThis.dispatchLog("Attaching: '" + participant.identity + "'");
            console.log("Reviewing previously active participants")
            console.dir(participant)
            let previewContainer = document.getElementById("remoteTrack");


            participant.on('trackSubscribed', track => {
                console.dir("particpant on trackSubscribed hit");
                console.dir(track);
                if (track.kind === 'data') {
                  track.on('message', data => {
                    EventBus.$emit("new_biomarkers", data);
                  });
                } else {
                  document.getElementById('remoteTrack').appendChild(track.attach());
                }
            });

            participant.on('trackPublished', track => {
                console.dir("particpant on trackPublished hit");
                console.dir(track);
                if (track.kind === 'data') {
                  track.on('message', data => {
                    EventBus.$emit("new_biomarkers", data);
                  });
                } else {
                  document.getElementById('remoteTrack').appendChild(track.attach());
                }
            });

            VueThis.attachParticipantTracks(participant, previewContainer);


          });

          // When a Participant joins the Room, log the event.

          room.on('participantConnected', participant => {
            VueThis.dispatchLog("Joining: '" + participant.identity + "'");
            console.log(`Participant "${participant.identity}" connected`);

            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                const track = publication.track;

                if (track.kind === 'data') {
                  track.on('message', data => {
                      EventBus.$emit("new_biomarkers", data);
                  });
                } else {                  
                  document.getElementById('remoteTrack').appendChild(track.attach());
                }
              }
            });

            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                const track = publication.track;

                if (track.kind === 'data') {
                  track.on('message', data => {
                      EventBus.$emit("new_biomarkers", data);
                  });
                } else {                  
                  document.getElementById('remoteTrack').appendChild(track.attach());
                }
              }
            });            

            participant.on('trackSubscribed', track => {
                console.dir("particpant on trackSubscribed hit");
                console.dir(track);
                if (track.kind === 'data') {
                  track.on('message', data => {
                    EventBus.$emit("new_biomarkers", data);
                  });
                } else {
                  document.getElementById('remoteTrack').appendChild(track.attach());
                }
            });

            participant.on('trackPublished', track => {
                console.dir("particpant on trackPublished hit");
                console.dir(track);
                if (track.kind === 'data') {
                  track.on('message', data => {
                    EventBus.$emit("new_biomarkers", data);
                  });
                } else {
                  document.getElementById('remoteTrack').appendChild(track.attach());
                }
            });
          });

          // When a Participant adds a Track, attach it to the DOM.

          room.on("trackAdded", function (track, participant) {
            VueThis.dispatchLog(
              participant.identity + " added track: " + track.kind
            );
            let previewContainer = document.getElementById("remoteTrack");
            VueThis.attachTracks([track], previewContainer);
          });

          // When a Participant removes a Track, detach it from the DOM.

          room.on("trackRemoved", function (track, participant) {
            VueThis.dispatchLog(
              participant.identity + " removed track: " + track.kind
            );
            VueThis.detachTracks([track]);
          });

          // When a Participant leaves the Room, detach its Tracks.
          room.on("participantDisconnected", function (participant) {
            VueThis.dispatchLog(
              "Participant '" + participant.identity + "' left the room"
            );
            VueThis.detachParticipantTracks(participant);
          });

          // if local preview is not active, create it
          // Moved to app launch
          // if (!VueThis.localTrack) {
          //   createLocalVideoTrack().then((track) => {
          //     let localMediaContainer = document.getElementById("localTrack");

          //     localMediaContainer.appendChild(track.attach());
          //     VueThis.localTrack = true;
          //   });
          // }
        });
      });
    },
  },
};
</script>

<style >
.remoteParentContainer  {
  margin:10px;
  border: 1px solid rgb(124, 129, 124);
  width:372px;
}
.remote_video_container {
  left: 0;
  margin: 0;
  border: 1px solid rgb(124, 129, 124);
}
.spacing {
  padding: 20px;
  width: 100%;
}
.roomTitle {
  border: 0px solid rgb(124, 129, 124);
  padding: 4px;
  background-color: #5e83a8;
  color:ghostwhite;
  text-align:center;
}

#remoteTrack video {
  height:800px;
  max-width:370px !important;
}

.col-md-6 {
  height:800px;
}
</style>
